.animated-button
  background: transparent
  font-family: 'Futura PT Demi', sans-serif
  font-weight: 600
  font-size: 1.25rem
  padding: 1.5rem 3rem
  cursor: pointer
  color: white
  outline: none
  border: 1px solid

  &:active, &:focus
    text-decoration: underline
